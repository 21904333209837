export default [
  {
    /**
     * 查询根目录
     */
    name: 'queryAllGroup',
    method: 'post',
    url: '/api/file/queryAllGroup',
  },
  {
    /**
     * 查询文件和文件夹
     */
    name: 'queryChildren',
    method: 'post',
    url: '/api/file/queryChildren',
  },
]
