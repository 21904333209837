export default [
  {
    /**
     * 执行Sql，临时
     */
    name: 'executeSql',
    method: 'post',
    url: '/api/common/executeSql',
  },
  {
    /**
     * 查询图表类型
     */
    name: 'queryAllChartType',
    method: 'post',
    url: '/api/common/queryAllChartType',
  },
  {
    /**
     * 查询时间类型
     */
    name: 'queryAllPeriod',
    method: 'post',
    url: '/api/common/queryAllPeriod',
  },
  {
    /**
     * 查询数据类型
     */
    name: 'queryAllDataType',
    method: 'post',
    url: '/api/common/queryAllDataType',
  },
  {
    /**
     * 查询颜色定义
     */
    name: 'queryColorDesc',
    method: 'post',
    url: '/api/common/queryColorDesc',
  },
]
