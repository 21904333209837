const modulesFiles = import.meta.globEager('./modules/*.js')

const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
  // const moduleName = modulePath.replace(/^\.\/modules\/(.*)\.\w+$/, '$1')
  const value = modulesFiles[modulePath]
  modules.push(...value.default)
  return modules
}, [])

export default modules
