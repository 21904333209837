import axios from 'axios'
import Vue from 'vue'
import { isType } from '@/utils'
const baseURL = import.meta.env.VITE_API_URL

const service = axios.create({
  baseURL, // url = base url + request url
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Device-Type': 'PC',
  },
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
})

service.interceptors.response.use(
  function(response) {
    let { data } = response
    // 对响应数据做点什么
    if (['000000'].includes(data.code)) {
      return response
    }
    if (response.data.code === '999999') {
      throw response.data.message
    } else if (['010002', '010006'].indexOf(response.data.code) > -1) {
      return response
    } else {
      throw response.data.message
    }
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  },
)

/**
 *需要实现IRunable接口，js中没有接口，需要在class实现run方法
 */
export default class AjaxRunable {
  ajax(config) {
    let promise = service.request(config)
    return new Promise((resolve, reject) => {
      return promise
        .then(function(res) {
          let allData = res.data
          let { code, data, message, fieldError } = allData

          if (code === '000000') {
            resolve(data)
          } else if (code === '010006') {
            window.location.href = `/login?returnUrl=${encodeURIComponent(window.location.href)}`
          } else {
            //这里的code可能是
            //'010002'
            //需要在调用接口的地方单独处理
            reject({
              code,
              data,
              message,
              fieldError,
            })
          }
        })
        .catch(function(error) {
          var message = error || 'new work error'

          Vue.prototype.$message.error({
            message,
          })

          reject(message)
        })
    })
  }

  run(param) {
    let data = param.params
    const { url: reqUrl } = param
    let params = {
      url: isType(reqUrl, 'string') ? reqUrl : reqUrl(param.params), // 兼容restful接口
      method: param.method,
    }
    if (param.method === 'get') {
      params.params = data
    } else if (param.method === 'put' || param.method === 'post' || param.method === 'patch') {
      if (param.isUpload) {
        var formData = new FormData()

        for (var key in data) {
          formData.append(key, data[key])
        }
        params.data = formData

        params.headers = {
          'Content-Type': 'multipart/form-data',
        }
      } else {
        params.data = data
      }
    } else {
      params.data = data
    }
    return this.ajax(params)
  }
}

// 处理restful地址
export function getRestUrl(url, data) {
  return url.replace(/{([\s\S^}]+?)}/g, (arg, $1) => {
    let v = data[$1]
    delete data[$1]
    return v
  })
}
