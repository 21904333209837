import Vue from 'vue'
import store from './store'
import { mapGetters } from 'vuex'
import { formatDate } from '@/utils'
import { Dialog } from 'element-ui'
const DialogConstructor = Vue.extend(Dialog)

Vue.mixin({
  filters: { formatDate },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    formatDate,
    openDialog(vNode, params) {
      let instance = new DialogConstructor({
        router: this.$router,
        store,
        el: document.createElement('div'),
        propsData: {
          width: params.width || '480px',
          visible: true,
          closeOnClickModal: false,
          ...params,
        },
      })

      vNode.componentOptions.listeners = {
        ...vNode.componentOptions.listeners,
        close: () => {
          instance.visible = false
        },
      }

      instance.$on('close', () => (instance.visible = false))
      instance.$on('closed', () => instance.$el.parentNode.removeChild(instance.$el))
      instance.$slots.default = [vNode]

      document.body.appendChild(instance.$el)
    },

    // 获取字典，已在router/index.js，写了一个全局的调用
    async getEnum(params = []) {
      await this.$store.dispatch('enum/getEnum', params)
    },
  },
})
