import service from '@/service'
import store from '@/store'
import { setStorage } from '@/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    // 首页
    path: '/',
    name: 'index',
    component: () => import('../views/main.vue'),
    children: [
      {
        // 首页，登录后
        path: '/',
        name: 'home',
        meta: {
          role: [0, 1, 2, 3, 4, 5],
          breads: [
            {
              title: 'Home',
            },
          ],
        },
        component: () => import('../views/home/index.vue'),
      },
      {
        path: '/data-map',
        name: 'data-map',
        component: () => import('../views/data/DataMap.vue'),
        meta: {
          title: 'Data Map',
          role: [1, 2, 4, 5],
          breads: [
            {
              title: 'Data Map',
            },
          ],
        },
      },
      {
        path: '/data-branch/chart',
        name: 'chart',
        component: () => import('../views/data/Charts.vue'),
        meta: {
          role: [1, 2, 4, 5],
          breads: [
            {
              title: 'My Data Branch',
            },
            {
              title: 'Create Charts',
            },
          ],
        },
      },
      {
        path: '/data-branch/chart/:chartId',
        name: 'edit-chart',
        component: () => import('../views/data/EditChart.vue'),
        meta: {
          role: [1, 2, 4, 5],
          breads: [
            {
              title: 'My Data Branch',
            },
            {
              path: '/data-branch/chart',
              title: 'Create Charts',
            },
            {
              title: 'Edit Charts',
            },
          ],
        },
      },
      {
        path: '/data-branch/view-chart',
        name: 'view-chart',
        component: () => import('../views/data/ViewChart.vue'),
        meta: {
          role: [1, 2, 4, 5],
          breads: [
            {
              title: 'My Data Branch',
            },
            {
              title: 'View Charts',
            },
          ],
        },
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/List.vue'),
        meta: {
          role: [0, 3],
          breads: [
            {
              title: 'User Management',
            },
          ],
        },
      },
      {
        path: '/owner',
        name: 'owner',
        component: () => import('../views/owner/List.vue'),
        meta: {
          title: 'Owner Management',
          role: [0],
        },
      },
      {
        path: '/data-report',
        name: 'data-report',
        component: () => import('../views/data-report/index.vue'),
        meta: {
          role: [0, 1, 2, 3, 4, 5],
          breads: [
            {
              title: 'Data Report',
            },
          ],
        },
      },
      {
        path: '/site',
        name: 'site',
        component: () => import('../views/site/List.vue'),
        meta: {
          role: [0, 1, 2, 3, 4, 5],
          breads: [
            {
              title: 'Site Management',
            },
          ],
        },
      },
      {
        path: '/site/:id',
        name: 'site-detail',
        component: () => import('../views/site/Detail.vue'),
        redirect: '/site/:id/index',
        children: [
          {
            path: '/site/:id/index',
            name: 'site-detail-index',
            component: () => import('../views/site/component/Index.vue'),
            meta: {
              title: 'Site Detail',
              role: [0, 1, 2, 3, 4, 5],
              breads: [
                {
                  title: 'Home',
                },
                {
                  title: 'Site Detail',
                },
              ],
            },
          },
          {
            path: '/site/:id/chart/:sNo/:ownerCode/:dataType/:parameterCode',
            name: 'site-chart',
            component: () => import('../views/site/component/SiteChart.vue'),
            meta: {
              role: [0, 1, 2, 3, 4, 5],
              breads: [
                {
                  title: 'Home',
                },
                {
                  title: 'Site Detail',
                },
              ],
            },
          },
        ],
      },
      {
        path: '/common',
        name: 'common',
        component: () => import('../views/common/Sql.vue'),
        meta: {
          role: [0, 1, 2, 3, 4, 5],
        },
      },
      {
        path: '/403',
        name: '403',
        component: () => import('../views/403.vue'),
        meta: {
          role: [0, 1, 2, 3, 4, 5],
        },
      },
    ],
  },
  {
    // 登录
    path: '/login',
    name: 'login',
    meta: {
      needLogin: false,
    },
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/reset/:token',
    name: 'reset',
    meta: {
      needLogin: false,
      background: '#f1f2f5',
    },
    component: () => import('../views/user/Reset.vue'),
  },
  {
    path: '/find',
    name: 'find',
    meta: {
      needLogin: false,
      background: '#f1f2f5',
    },
    component: () => import('../views/user/Find.vue'),
  },
]

function createRouter(base = '/', mode = 'history') {
  const router = new VueRouter({
    mode,
    base,
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    if (store.getters.userInfo.isLogined === undefined) {
      let userInfo = await service.queryAuth().executeSerial()
      await store.dispatch('user/updateUser', userInfo)
    }

    if (store.getters.userInfo.isLogined) {
      setStorage('accept', true)
      if (to.name !== 'login') {
        if (to.meta?.role?.includes(store.getters.userInfo.userRole)) {
          next()
        } else {
          if (to.meta?.needLogin === false) {
            next({ name: 'home' })
          } else {
            next({ name: '403' })
          }
        }
      } else {
        next({ name: 'home' })
      }
    } else {
      if (to.meta?.needLogin === false) {
        next()
      } else {
        next({ name: 'login' })
      }
    }
  })

  router.afterEach(to => {
    if (to.meta?.background) {
      document.body.style.background = to.meta.background
    } else {
      document.body.style.background = '#fff'
    }
  })
  return router
}

export default createRouter
