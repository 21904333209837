import dayjs from 'dayjs'
import Vue from 'vue'

export const bus = new Vue()

export function loadScript(url) {
  let script = document.createElement('script')
  let promise = new Promise((resolve, reject) => {
    script.onerror = reject
    script.onload = script.onreadystatechange = function(e) {
      if (!e.readyState || e.readyState === 'loaded' || e.readyState === 'complete') {
        script.onload = script.onreadystatechange = null
        resolve()
        document.head.removeChild(script)
      }
    }
    script.src = url
    document.head.appendChild(script)
  })
  return promise
}

export function isType(obj, type) {
  return (
    Object.prototype.toString
      .call(obj)
      .slice(8, -1)
      .toLowerCase() === type.toLowerCase()
  )
}

/*
  存储localStorage
 */
export function setStorage(name, obj) {
  let str = JSON.stringify(obj)
  localStorage[name] = str
}
/*
  获取localStorage
 */
export function getStorage(name) {
  if (localStorage[name]) {
    return JSON.parse(localStorage[name])
  }
}
/*
  获取localStorage，并清除
 */
export function getStorageOnce(name) {
  if (localStorage[name]) {
    let val = localStorage[name]
    removeStorage(name)
    return JSON.parse(val)
  }
}
/*
  清除localStorage
 */
export function removeStorage(name) {
  localStorage.removeItem(name)
}

/*
日期格式化
*/
export function formatDate(time, format = 'DD/MM/YYYY', defaultRe = '--') {
  if (!time) {
    return defaultRe
  }
  return dayjs(time).format(format)
}

/*
金额格式化
*/
export function formatCurrency(s, bit = 2) {
  // if (!s) return '0.00';
  s = String(s)
  if (!s.replace(/^\s+|\s+$/g, '')) return '0.00'
  let bitTxt = ''
  if (!/^(-?\d+)(\.\d*)?$/.test(s)) {
    return '-'
  }

  let sign = ''
  s = Number(s)
  if (s < 0) {
    sign = '-'
  } else {
    sign = ''
  }
  s = Math.abs(s)
  if (/^\d+$/.test(s)) {
    for (let i = 0; i < bit; i++) {
      bitTxt += '0'
    }
    if (bitTxt === '') return sign + (s + '').replace(/\B(?=(\d{3})+$)/g, ',')
    return sign + (s + '').replace(/\B(?=(\d{3})+$)/g, ',') + '.' + bitTxt
  }
  if (/^(\d+)\.(\d+)$/.test(s)) {
    s = s + '0'
    var v = s.split('.')
    var f = (v[0] + '').replace(/\B(?=(\d{3})+$)/g, ',')
    var h = v[1].substring(0, bit)
    if (h === '') return sign + f
    return sign + f + '.' + h
  }
}

// 节流
export function throttle(fn, interval, options = { leading: true, trailing: false }) {
  // * 记录上一次的开始时间
  let lastTime = 0
  // * 将是否第一次触发和最后一次触发取出来
  const { leading, trailing, resultCallback } = options

  // * 最后一次执行的定时器
  let timer = null

  // * 事件触发时，真正执行的函数
  const _throttle = function(...args) {
    return new Promise(resolve => {
      // * 获取当前事件触发时的时间  getTime 获取的是时间戳
      const nowTime = new Date().getTime()
      // * 第一次不触发的时候，将lastTime设置为nowTime
      if (!lastTime && !leading) lastTime = nowTime
      // * 使用当前触发的时间和上一次的开始时间、时间间隔，计算出还剩多长时间触发函数。
      const remainTime = interval - (nowTime - lastTime)
      if (remainTime <= 0) {
        // * 真正触发函数
        const result = fn.apply(this, args)
        if (resultCallback) resultCallback(result)
        resolve(result)
        // * 保留上次触发的时间
        lastTime = nowTime

        // * 清空timer
        if (timer) {
          clearTimeout(timer)
          timer = null
          return
        }
      }
      if (trailing && !timer) {
        //* 最后一次执行
        timer = setTimeout(() => {
          timer = null
          lastTime = !leading ? 0 : new Date().getTime()
          const result = fn.apply(this, args)
          if (resultCallback) resultCallback(result)
          resolve(result)
        }, remainTime)
      }
    })
  }

  _throttle.cancel = function() {
    if (timer) clearTimeout(timer)
    timer = null
    lastTime = 0
  }

  return _throttle
}

/**
 * 防抖
 * @param {Function} fn 要执行的方法
 * @param {Number} delay 延迟时间
 * @param {Boolean} immediate 是否立即执行
 * @param {Function} resultCallback 结果回调
 * @returns 可执行的方法
 */
export function debounce(fn, delay, immediate = false, resultCallback) {
  // * 定义了一个定时器，保存上一次的定时器
  let timer = null
  let isInvoke = false
  // * 真正执行的函数
  const _debounce = function(...args) {
    // * 如果上一次已经设置了定时器，就将上一次的定时器取消，
    if (timer) clearTimeout(timer)

    // * 判断是否需要 立即执行
    if (immediate && !isInvoke) {
      const result = fn.apply(this, args)
      if (resultCallback) resultCallback(result)
      isInvoke = true
    } else {
      timer = setTimeout(() => {
        // * 外部传入要真正执行的函数
        const result = fn.apply(this, args)
        if (resultCallback) resultCallback(result)
        isInvoke = false
      }, delay)
    }
  }

  // * 封装取消功能
  _debounce.cancel = function() {
    if (timer) clearInterval(timer)
    timer = null
    isInvoke = false
  }

  return _debounce
}
