export default [
  {
    /**
     * 查询组
     */
    name: 'queryOwnerList',
    method: 'post',
    url: '/api/owner/queryList',
  },
  {
    /**
     * 查询组
     */
    name: 'queryAllOwner',
    method: 'post',
    url: '/api/owner/queryAll',
  },
]
