export default [
  {
    /**
     * 查询参数分组
     */
    name: 'queryAllParameterGroupByOwner',
    method: 'post',
    url: '/api/parameter/queryAllParameterGroupByOwner',
  },
  {
    /**
     * 查询所有参数
     */
    name: 'queryAllParameter',
    method: 'post',
    url: '/api/parameter/queryAllParameter',
  },
  {
    /**
     * 查询站点参数
     */
    name: 'queryAllParameterBySiteNo',
    method: 'post',
    url: '/api/parameter/queryAllParameterBySiteNo',
  },
]
